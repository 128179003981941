export const CFDI_USES = [
  {
    code: 'G01',
    description: 'Adquisición de mercancias',
    fisica: true,
    moral: true
  },
  {
    code: 'G02',
    description: 'Devoluciones, descuentos o bonificaciones',
    fisica: true,
    moral: true
  },
  {
    code: 'G03',
    description: 'Gastos en general',
    fisica: true,
    moral: true
  },
  {
    code: 'I01',
    description: 'Construcciones',
    fisica: true,
    moral: true
  },
  {
    code: 'I02',
    description: 'Mobilario y equipo de oficina por inversiones',
    fisica: true,
    moral: true
  },
  {
    code: 'I03',
    description: 'Equipo de transporte',
    fisica: true,
    moral: true
  },
  {
    code: 'I04',
    description: 'Equipo de computo y accesorios',
    fisica: true,
    moral: true
  },
  {
    code: 'I05',
    description: 'Dados, troqueles, moldes, matrices y herramental',
    fisica: true,
    moral: true
  },
  {
    code: 'I06',
    description: 'Comunicaciones telefónicas',
    fisica: true,
    moral: true
  },
  {
    code: 'I07',
    description: 'Comunicaciones satelitales',
    fisica: true,
    moral: true
  },
  {
    code: 'I08',
    description: 'Otra maquinaria y equipo',
    fisica: true,
    moral: true
  },
  {
    code: 'D01',
    description: 'Honorarios médicos, dentales y gastos hospitalarios.',
    fisica: true,
    moral: false
  },
  {
    code: 'D02',
    description: 'Gastos médicos por incapacidad o discapacidad',
    fisica: true,
    moral: false
  },
  {
    code: 'D03',
    description: 'Gastos funerales.',
    fisica: true,
    moral: false
  },
  {
    code: 'D04',
    description: 'Donativos.',
    fisica: true,
    moral: false
  },
  {
    code: 'D05',
    description: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    fisica: true,
    moral: false
  },
  {
    code: 'D06',
    description: 'Aportaciones voluntarias al SAR.',
    fisica: true,
    moral: false
  },
  {
    code: 'D07',
    description: 'Primas por seguros de gastos médicos.',
    fisica: true,
    moral: false
  },
  {
    code: 'D08',
    description: 'Gastos de transportación escolar obligatoria.',
    fisica: true,
    moral: false
  },
  {
    code: 'D09',
    description: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    fisica: true,
    moral: false
  },
  {
    code: 'D10',
    description: 'Pagos por servicios educativos (colegiaturas)',
    fisica: true,
    moral: false
  },
  {
    code: 'S01',
    description: 'Sin efectos fiscales.',
    fisica: true,
    moral: true
  },
  {
    code: 'CP01',
    description: 'Pagos',
    fisica: true,
    moral: true
  },
  {
    code: 'CN01',
    description: 'Nómina',
    fisica: true,
    moral: false
  }
]
