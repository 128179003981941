import { ApiService } from 'core/services'
import { CfdiLetterPorte, GenerateQrSatRequest } from './types'

class BillingService {
  public static instance: BillingService

  constructor() {
    if (BillingService.instance) {
      return BillingService.instance
    }
    BillingService.instance = this
  }

  getComplementLEtterPortData(letterPorte: string): Promise<CfdiLetterPorte[]> {
    return ApiService.get<CfdiLetterPorte[]>(`complementLetterPorte/getLetterPorteXML/${letterPorte}`)
  }

  generateQrSat(data: GenerateQrSatRequest) {
    const { timbreFiscalId, receptorRfc, emisorRfc, total, sello } = data
    const cadenaSello = sello.substr(sello.length - 8)
    return `https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=${timbreFiscalId}&re=${emisorRfc}&rr=${receptorRfc}&tt=${total}&fe=${cadenaSello}`
  }
}

const instance = new BillingService()

Object.freeze(instance)

export default instance
